import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import SignUpView from '../views/SignUpView.vue'
import RequestResetPasswordView from '../views/RequestResetPasswordView.vue'

const routes = [
  {
    // トップページ
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    // トップページ
    path: '/noauth',
    name: 'noauthhome',
    component: HomeView,
  },
  {
    // ログイン
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    // 新規登録
    path: '/signup',
    name: 'signup',
    component: SignUpView,
  },
  {
    // パスワード再設定要請
    path: '/requestResetPassword',
    name: 'requestResetPassword',
    component: RequestResetPasswordView,
  },
  {
    // ユーザーページ
    path: '/user/:uid',
    name: 'user',
    component: () => import('../views/UserView.vue')
  },
  {
    // 投稿詳細ページ
    path: '/postdetail/:id',
    name: 'postdetail',
    component: () => import('../views/PostDetailsView.vue')
  },
  {
    // 検索ページ
    path: '/search/:text',
    name: 'search',
    component: () => import('../views/SerchView.vue')
  },
  {
    // タグなし検索ページ
    path: '/notextsearch',
    name: 'notagsearch',
    component: () => import('../views/NotagSerchView.vue')
  },
  {
    // 認証無し検索ページ
    path: '/noauthsearch/:tag',
    name: 'noauthsearch',
    component: () => import('../views/NoauthSearch.vue')
  },
  {
    // 認証無しタグなし検索ページ
    path: '/noauthnotextsearch',
    name: 'noauthnotagsearch',
    component: () => import('../views/NoauthNotagSearchView.vue')
  },
  {
    // お気に入りページ
    path: '/favorite',
    name: 'favorite',
    component: () => import('../views/FavoriteView.vue')
  },
  {
    // 自分の投稿ページ
    path: '/mypost',
    name: 'mypost',
    component: () => import('../views/MypostView.vue')
  },
  {
    // ブロックワード
    path: '/blockword',
    name: 'blockword',
    component: () => import('../views/BlocksView.vue')
  },
  {
    // 検索プリセット
    path: '/searchpriset',
    name: 'searchpriset',
    component: () => import('../views/SearchPrisetView.vue')
  },
  {
    // ルクス(ポイント)
    path: '/luxpost',
    name: 'luxpost',
    component: () => import('../views/LuxpostView.vue')
  },
  {
    // ブロックワード
    path: '/draftslist',
    name: 'draftslist',
    component: () => import('../views/DraftsListView.vue')
  },
  {
    // 特定商取引
    path: '/transaction',
    name: 'transaction',
    component: () => import('../views/Transaction.vue')
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const authLog = localStorage.getItem('authlog');

  // ログインページ、認証無し検索ページ、タグなし検索ページは常に許可する
  if (to.path === '/login' || to.path === '/signup' || to.path === '/requestResetPassword' || to.path === '/transaction' || to.path.includes('/noauthsearch/') || to.path === '/noauthnotextsearch') {
    next();
  } else if (authLog === 'true') {
    // 認証済みの場合は常に許可する
    next();
  } else if (to.path === '/noauth' && (authLog === 'false' || authLog === null || authLog === undefined)) {
    // 認証無しページへの遷移の場合は常に許可する
    next();
  } else {
    // それ以外の場合は認証無しページにリダイレクトする
    next('/noauth');
  }
});




export default router;
