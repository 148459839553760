<!-- PostModal.vue -->

<template>
    <div class="postmodal" :class="{ open: isPostModalOpen }">
        <div class="modalbox">
            <button @click="closePostModal" class="backbtn">
                <img src="../../assets/backbtn.png" alt="" class="smallicon">
            </button>

            <div class="modalbox--header">
                <div>
                    <label for="customDate">
                        <input type="date" id="customDate" v-model="today" @input="updateToday">
                    </label>
                </div>

                <button @click="Savedraft">
                    下書き保存
                </button>
            </div>

            <div class="modalbox--index">
                <div>
                    タイトル
                    <span>{{ title.length }}/64</span>
                    <b>必須</b>
                </div>
                <input type="text" v-model="title" @input="updateCount('title')" class="modalbox--text" placeholder="タイトルを書く。">
            </div>


            <div class="tags">
                <div class="modalbox--index" v-for="(tag, index) in tags" :key="index">
                    <div>
                        タグ
                        <span>{{ tag.length }}/16</span>
                    </div>
                    <button @click="removeTag(index)" class="remtag">
                        <img src="../../assets/backbtn.png" alt="" class="smallicon">
                    </button>
                    <input type="text" v-model="tags[index]" @input="updateCount('tags', index)" class="modalbox--text" placeholder="タグを書く。">
                </div>
                <button @click="addTagInput" class="addtag" :class="{ 'hidden': tags.length >= 16 }">
                    タグを追加
                </button>
            </div>

            <div class="modalbox--index">
                <div>
                    前書き
                    <span>{{ lead.length }}/128</span>
                </div>
                <!-- <input type="text" v-model="lead" @input="updateCount('lead')" class="modalbox--lead"> -->
                <textarea v-model="lead" @input="updateCount('lead')" name="" id="" cols="30" rows="5" class="modalbox--lead" placeholder="前書きを書く。"></textarea>
            </div>
            <div class="modalbox--img modalbox--index">
                <div>
                    画像選択
                    <span>{{ selectedImages.length }}/4</span>
                </div>
                <div class="images">
                    <input class="image" :class="{ 'hidden': selectedImages.length >= 4 }" type="file" @change="handleImageSelection" accept="image/*" aria-label="Choose File" aria-hidden="true">
                    <div class="modalbox--imglist modalbox--index" v-if="selectedImages.length > 0">
                        <ul>
                            <li v-for="(image, index) in selectedImages" :key="index">
                                <img :src="getObjectURL(image)" alt="Selected Image" @touchstart="showDelImgModal(index)">
                                <button @click="removeImage(index)"></button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modalbox--index">
                <div>
                    本文
                    <span>{{ body.length }}/4096</span>
                </div>
                <textarea v-model="body" @input="updateCount('body')" name="" id="" cols="30" rows="8" class="modalbox--textarea" placeholder="本文を書く。"></textarea>
            </div>

            <div class="modalbox--index modalbox--postbtn">
                <button @click="newpost" class="postbtn" :class="{ disabled: isPosting }" :disabled="isPosting">
                    <div class="postText"><span v-if="isPosting" class="loading-overlay"><i class="fa fa-spinner fa-pulse fa-3x fa-fw loading-image"></i></span>投 稿</div>
                    <div class="postContents">タグ解放 {{ tagsCount }}</div>
                    <div class="postContents"><img src="../../assets/lxinpost.png" alt="" class="smallicon">-{{ needLx }}</div>
                </button>
            </div>
        </div>
    </div>

    <div class="MessageModal" v-if="isBackMessageModalOpen" @click.self="closeBackMessageModal">
        <div class="back_MessageModal_Body">
            <p>記事を下書き保存せず削除しますか？</p>
            <button class="btn_Modal" @click="DeletePost">削除</button>
            <button class="btn_Modal" @click="SavePost">保存</button>
        </div>
    </div>

    <div class="MessageModal" v-if="isNotLxMsgModalOpen" @click.self="closeNotLxMsgModal">
        <div class="NotLxMsgModal_Body">
            <div class="closeNotLxMsgbtn"> 
                <button @click="closeNotLxMsgModal">
                    <img src="../../assets/backbtn.png" alt="" class="smallicon">
                </button>
            </div>
            <p>投稿に必要なルクスが不足しています。</p>
            <button class="btn_Modal" @click="showChargeLx"><img src="../../assets/lxinpost.png" alt="" class="smallicon">チャージ</button>
            <button class="btn_Modal" @click="SavePost">下書き保存</button>
        </div>
    </div>

    <!-- ChargeModal コンポーネントの追加 -->
    <ChargeModal :modalOpen="isChargeModalOpen" @closeChargeModal="closeChargeModal"></ChargeModal>

    <div class="MessageModal" v-if="isMessageModalOpen" @click.self="closeMessageModal(closeMode)">
        <div class="MessageModal_Body">
            <p>{{ message }}</p>
            <button class="btn_Modal" @click="closeMessageModal(closeMode)">OK</button>
        </div>
    </div>

    <div class="MessageModal" v-if="isDelImgModalOpen" @click.self="closeDelImgModal">
        <div class="back_MessageModal_Body">
            <p>画像を消去しますか？</p>
            <button class="btn_Modal" @click="PostDelImg(DelImg)">はい</button>
            <button class="btn_Modal" @click="closeDelImgModal">いいえ</button>
        </div>
    </div>
</template>

<script>
import { collection, doc, setDoc, getDoc, runTransaction, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../../main';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ChargeModal from '../chargeModal/chargeModal.vue';

    export default {
        components: { 
            ChargeModal
        },
        props: {
            isPostModalOpen: Boolean,
            selectDay: String,
        },
        data() {
            return {
                isBackMessageModalOpen: false,
                isMessageModalOpen: false,
                isDelImgModalOpen: false,
                isNotLxMsgModalOpen: false,
                isChargeModalOpen: false,
                message: '',
                closeMode: '',
                DelImg: '',
                currentUser: null,
                title: '',
                tags: [''],
                lead: '',
                body: '',
                todays: '',
                setday: '',
                tagsCount: 1,
                needLx: 300,
                selectedImages: [],
                today: '',
                isPosting: false, 
            };
        },
        created() {
            this.today = this.selectDay || this.getFormattedDate();
            this.initTodayDate();
            auth.onAuthStateChanged(user => {
                this.currentUser = user;
                //console.log('ユーザー情報:', this.currentUser);
                //console.log('セット日:', this.setday);
            });
        },
        watch: {
            selectDay(newDay) {
                this.today = newDay;
            },
            selectedImages: {
                handler(newImages) {
                    this.$nextTick(() => {
                        const inputElement = this.$refs.postmodal ? this.$refs.postmodal.querySelector('input[type="file"]') : null;
                        if (inputElement) {
                            if (newImages.length >= 4) {
                                inputElement.classList.add('hidden');
                            } else {
                                inputElement.classList.remove('hidden');
                            }
                        }
                    });
                },
                deep: true,
            },
        },
        methods: {
            getFormattedDate() {
                const today = new Date();
                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, '0');
                const day = String(today.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            },
            updateToday() {
                //console.log('選択された日付:', this.today);
                const selectedDate = new Date(this.today);
                selectedDate.setHours(0, 0, 0, 0);
                const year = selectedDate.getFullYear();
                const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
                const day = String(selectedDate.getDate()).padStart(2, '0');
                this.setday = `${year}-${month}-${day}`;
                sessionStorage.setItem('selectDAY', this.today);
            },

            // 削除・保存確認メッセージDLGの表示
            showBackMessageModal() {
                this.isBackMessageModalOpen = true;
            },
            // 削除・保存確認メッセージDLGの非表示
            closeBackMessageModal() {
                this.isBackMessageModalOpen = false;
            },
            // ルクス不足メッセージDLGの表示
            showNotLxMsgModal() {
                this.isNotLxMsgModalOpen = true;
            },
            // ルクス不足メッセージDLGの非表示
            closeNotLxMsgModal() {
                this.isNotLxMsgModalOpen = false;
            },
            showChargeLx() {
                this.closeNotLxMsgModal();
                this.isChargeModalOpen = true;
            },
            closeChargeModal() {
                this.isChargeModalOpen = false;
            },

            // 確認メッセージDLGの表示
            showMessageModal(message, mode) {
                this.message = message;
                this.closeMode = mode;
                this.isMessageModalOpen = true;
            },
            // 確認メッセージDLGの非表示
            closeMessageModal(closeMode) {
                this.isMessageModalOpen = false;
                if (closeMode == 'closePostModal') {
                    this.$emit('update:isPostModalOpen', false);
                }
                if (closeMode == 'closeAddPostModal') {
                    this.$emit('update:isPostModalOpen', false);
                    this.$router.go({ path: this.$route.path, force: true });
                }
            },
            // 画像削除確認メッセージDLGの表示
            showDelImgModal(DelImg) {
                this.DelImg = DelImg;
                this.isDelImgModalOpen = true;
            },
            // 確認メッセージからの画像削除処理
            PostDelImg(DelImg) {
                this.removeImage(DelImg);
                this.isDelImgModalOpen = false;
            },
            // 画像削除確認メッセージDLGの非表示
            closeDelImgModal() {
                this.isDelImgModalOpen = false;
            },

            // 「✕」ボタン押下時
            closePostModal() {
                if (this.title.trim() !== '' || this.tags.some(tag => tag.trim() !== '') || 
                    this.lead.trim() !== '' || this.selectedImages.length > 0 || 
                    this.body.trim() !== '') {
                    // 本文、タグ、前書き、本文のいずれかが入力されている、または画像が選択されている場合、削除・保存確認メッセージを表示
                    this.showBackMessageModal();
                } else{
                    // 記事投稿・編集Mを非表示
                    this.$emit('update:isPostModalOpen', false);
                }

            },
            //記事の削除処理
            DeletePost() {
                this.closeBackMessageModal();
                this.closeModal();
                this.showMessageModal('削除しました', 'closePostModal');
            },
            //記事の保存処理
            SavePost() {
                this.closeBackMessageModal();
                this.closeNotLxMsgModal();
                this.Savedraft();
            },
            closeModal() {
                //this.$emit('update:isPostModalOpen', false);
                this.title = ''; // タイトルをクリア
                this.lead = ''; // リードをクリア
                this.body = ''; // 本文をクリア
                this.selectedImages = []; // 選択された画像をクリア
            },
            updateCount(field, index) {
                // フィールドごとに文字数を更新
                if (field === 'title' && this.title.length > 64) {
                    //タイトル、最大入力文字数は64
                    this.title = this.title.slice(0, 64);
                }
                if (field === 'tags' && this.tags[index].length > 16) {
                    //タグ、最大入力文字数は16
                    this.tags[index] = this.tags[index].slice(0, 16);
                }
                if (field === 'lead' && this.lead.length > 128) {
                    //前書き、最大入力文字数は128
                    this.lead = this.lead.slice(0, 128);
                }
                if (field === 'body' && this.body.length > 4096) {
                    //本文、最大入力文字数は128
                    this.body = this.body.slice(0, 4096);
                }
            },
            handleImageSelection(event) {
                const files = event.target.files;
                for (let i = 0; i < files.length; i++) {
                    if (this.selectedImages.length < 4) {
                        this.selectedImages.push(files[i]);
                    }
                }
            },
            removeImage(index) {
                this.selectedImages.splice(index, 1);
            },
            getObjectURL(file) {
                if (!file) return '';
                return window.URL.createObjectURL(file);
            },
            initTodayDate() {
                const storedDate = sessionStorage.getItem('selectDAY');
                if (storedDate) {
                    this.today = storedDate;
                } else {
                    const today = new Date();
                    const year = today.getFullYear();
                    const month = String(today.getMonth() + 1).padStart(2, '0');
                    const day = String(today.getDate()).padStart(2, '0');
                    this.today = `${year}-${month}-${day}`;
                }
                this.todays = this.today;
                this.setday = this.today;
            },

            //記事投稿処理
            async newpost() {
                if (this.isPosting) {
                    // 既に投稿処理中の場合は何もしない
                    return;
                }
                this.isPosting = true; // 投稿処理を開始

                try {
                    if (this.title === '') {
                        this.showMessageModal('タイトルを入力してください。', 'closeMessageModal');
                        return;
                    }

                    const uid = this.currentUser.uid;
                    const userRef = doc(db, 'users', uid);

                    // 画像のアップロード
                    const storage = getStorage();
                    const imageUrls = [];
                    for (let i = 0; i < this.selectedImages.length; i++) {
                        const image = this.selectedImages[i];
                        const storageRef = ref(storage, `images/${uid}/${Date.now()}_${image.name}`);
                        await uploadBytes(storageRef, image);
                        const downloadURL = await getDownloadURL(storageRef);
                        imageUrls.push(downloadURL);
                    }

                    // 投稿データの準備
                    const postData = {
                        title: this.title,
                        tags: this.tags,
                        tagsCount: this.tags.length,
                        lead: this.lead,
                        body: this.body,
                        todays: this.todays,
                        setdays: this.setday,
                        imageUrls: imageUrls,
                        point: 0,
                        originid: '',
                        dataCreated: serverTimestamp(),
                        dataUpdated: serverTimestamp(),
                    };

                    await runTransaction(db, async (transaction) => {
                        // ユーザードキュメントの取得
                        const userDoc = await transaction.get(userRef);
                        if (!userDoc.exists()) {
                            throw new Error('USER NOT FOUND');
                        }

                        const userData = userDoc.data();
                        const currentPoints = userData.point || 0;

                        if (currentPoints < this.needLx) {
                            throw new Error('NOT ENOUGH LX');
                        }

                        postData.originid = userData.originid;

                        // 投稿ドキュメントのリファレンス作成
                        const postRef = doc(collection(db, 'whispers'));

                        // 長さログのドキュメントのリファレンスとデータ取得
                        const totalLength = this.title.length + this.lead.length + this.body.length;
                        const todayDate = new Date();
                        const formattedDate = todayDate.toISOString().split('T')[0].replace(/-/g, '');

                        const lengthLogRef = doc(db, 'users', uid, 'myPostlengthLog', formattedDate);
                        const lengthLogDoc = await transaction.get(lengthLogRef);

                        let newALLlength = totalLength;
                        if (lengthLogDoc.exists()) {
                            const existingData = lengthLogDoc.data();
                            newALLlength += existingData.ALLlength || 0;
                        }

                        // ユーザーのポイントを更新
                        transaction.update(userRef, {
                            point: currentPoints - this.needLx,
                        });

                        // 投稿データを保存
                        transaction.set(postRef, postData);

                        // 長さログを更新または作成
                        transaction.set(lengthLogRef, {
                            ALLlength: newALLlength,
                        });
                    });

                    this.closeModal();
                    this.showMessageModal('記事が投稿されました。', 'closeAddPostModal');
                } catch (error) {
                    if (error.message === 'NOT ENOUGH LX') {
                        // ルクスが不足し投稿できない場合
                        this.showNotLxMsgModal();
                    } else {
                        this.showMessageModal('投稿に失敗しました。\nサーバーに問題が発生している可能性があります。\nしばらくしてから再試行してください。', 'closeMessageModal');
                    }
                } finally {
                     this.isPosting = false; // 処理終了後にボタンを再度有効化
                }
            },

            //下書き保存処理
            async Savedraft() {
                try {
                    // 本文、タグ、前書き、本文が入力されていない、かつ画像が選択されていない場合
                    if (this.title.trim() == '' && this.tags.some(tag => tag.trim() == '') && 
                    this.lead.trim() == '' && this.selectedImages.length == 0 && 
                    this.body.trim() == '') {
                        this.showMessageModal('保存する記事がありません。', 'closeMessageModal');
                        return;
                    }

                    // Storageオブジェクトを取得
                    const storage = getStorage();

                    // ユーザーのドキュメントを取得
                    const userRef = doc(db, 'users', this.currentUser.uid);
                    const userDoc = await getDoc(userRef);

                    // 選択された画像のダウンロードURLを取得するための配列を作成
                    const imageUrls = [];
                    for (let i = 0; i < this.selectedImages.length; i++) {
                        const image = this.selectedImages[i];
                        const storageRef = ref(storage, `images/${this.currentUser.uid}/${Date.now()}_${image.name}`);
                        await uploadBytes(storageRef, image);

                        // 画像のダウンロードURLを取得して配列に追加
                        const downloadURL = await getDownloadURL(storageRef);
                        imageUrls.push(downloadURL);
                    }

                    // Firestoreに保存するデータを作成
                    const postData = {
                        title: this.title,
                        tags: this.tags,
                        lead: this.lead,
                        body: this.body,
                        todays: this.todays,
                        setdays:this.setday,
                        imageUrls: imageUrls,
                        point:0,
                        originid: userDoc.data().originid, // ユーザーのidを取得
                        dataCreated: serverTimestamp(),
                        dataUpdated: serverTimestamp(),
                    };

                    // Firestoreにデータを保存
                    const postRef = doc(collection(db, 'users',this.currentUser.uid, 'savedraft'));
                    await setDoc(postRef, postData);

                    // 投稿後の処理
                    this.closeModal();
                    this.showMessageModal('記事を下書き保存しました。', 'closeAddPostModal');

                } catch (error) {
                    // エラー発生時、エラーメッセージを表示
                    this.showMessageModal('記事の下書き保存に失敗しました。\nサーバーに問題が発生している可能性があります。\nしばらくしてから再試行してください。', 'closeMessageModal');
                }
            },
            addTagInput() {
                this.tags.push(''); // タグ入力欄を追加する
                
                // 消費ルクスの計算
                let tagsLx = 0;
                if (this.tags.length > 0) {
                    let tagsCount = this.tags.length - 1;
                    tagsLx = tagsCount * 20;
                }

                this.tagsCount = this.tags.length;
                this.needLx = tagsLx + 300;
            },
            removeTag(index) {
                this.tags.splice(index, 1); // 指定されたインデックスのタグを削除する

                // 消費ルクスの計算
                let tagsLx = 0;
                if (this.tags.length > 0) {
                    let tagsCount = this.tags.length - 1;
                    tagsLx = tagsCount * 20;
                }

                this.tagsCount = this.tags.length;
                this.needLx = tagsLx + 300;
            }
        },
    };
</script>

<style lang="scss" scoped>

    .tags{
        .modalbox--index{
            white-space: nowrap;
            .modalbox--text{
                width: 400px !important;
                margin-left: 10px;
                @media (max-width: 599px) {
                    width: calc(100% - 36px) !important;
                }
            }
        }
        .addtag{
            margin: 0 20px;
            font-size: 15px;
            background: #C1C1C1;
            color: #323232;
            padding: 5px 15px;
            border-radius: 50px;
            font-weight: 600;
        }
        .remtag{
            position: relative;
        }
        button{
            .smallicon{
                width: 26px;
            }
        }
    }

    .postmodal {
        display: none;
    }

    .postmodal.open {
        display: block;
        position: absolute;
        z-index: 10000;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
        background:linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 320px;
        .modalbox{
            width: 80%;
            height: 95%;
            overflow-x: scroll;
            overflow-x: auto;
            color: #fff;
            background:linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
            border-radius: 10px ;
            @media screen and (max-width: 1000px) {
                width: 100%;
                height: 95%;
            }
            &--header{
                display: flex;
                align-items: center;
                font-size: 50px;
                font-weight: 700;
                justify-content: space-between;
                margin: 20px;
                @media screen and (max-width: 599px) {
                    width: 90%;
                    min-width: 290px;
                    font-size: 38px;
                    align-items: flex-end;
                    flex-direction: column-reverse;
                }
                @media screen and (max-width: 360px) {
                    font-size: 34px;
                }
                input[type="date"] {
                    width: calc(100% - 1px);
                    box-sizing: border-box;
                    background-image: url("../../assets/calendar.png"); // カスタムアイコンのパス
                    background-repeat: no-repeat;
                    background-position: right 10px center; // アイコンの位置を右側に設定
                    cursor: pointer; // カーソルをポインターに設定
                    -webkit-appearance: none; // ブラウザデフォルトのスタイルを無効化
                    appearance: none;
                    background-size: 30px; // アイコンのサイズを指定
                    padding-right: 40px; // カレンダーアイコンのためのスペースを追加
                    text-align: left;
                }
                input[type="date"]::-webkit-calendar-picker-indicator {
                    opacity: 0; // インジケーターを透明にする
                    width: 40px; // インジケーターの幅をアイコンの幅に合わせる
                    height: 30px; // インジケーターの高さをアイコンの高さに合わせる
                    cursor: pointer; // カーソルをポインターに設定
                    position: relative;
                    right: -30px; // 右からの位置を指定
                }
                div { 
                    @media (max-width: 599px) {
                        width: 100%;
                        min-width: 290px;
                    }
                }
                button{
                    background: #C1C1C1;
                    color: #323232;
                    font-size:15px;
                    padding:5px 15px;
                    border-radius: 50px;
                    font-weight: 600;
                }
            }
            &--imglist{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                margin: 0 20px !important;
                ul{
                    width: 100%;
                    display: grid;
                    grid-template-columns: auto auto;
                    grid-template-rows: auto auto;
                    grid-gap: 10px;
                    li{
                        width: 100%;
                        height: 25rem;
                        position: relative;
                        margin: 0 15px;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                        button{
                            display: none;
                            position: absolute;
                            background: url("../../assets/X.png") no-repeat center center; /* 画像のパスと表示設定 */
                            background-size: contain;
                            width: 11rem;
                            height: 11rem;
                            border-radius: 50%;
                            background-color: #C1C1C1;
                            right: 1.5rem;
                            top: 1.5rem;
                            align-items: center;
                            justify-content: center;
                            color: #323232;
                        }
                    }
                    li:hover button {
                       display: flex;
                    }
                }
            }
            .backbtn{
                position: relative;
                left: 95%;
                top: 15px;
                width: 30px;
                @media screen and (max-width: 599px) {
                    left: 86%;
                    top: 2px;
                }
                img{
                    cursor: pointer;
                    width: 30px;
                }
            }
            &--index{
                margin: 20px;
                font-size: 15px;
                b{
                    font-size: 12px;
                    color: #ea0f29;
                    margin-left:10px;
                    font-weight: 400;
                }
            }
            &--text{
                width: 100%;
                background-color: #323232;
                padding: 5px;
            }

            &--lead{
                width: 100%;
                background-color: #323232;
                padding: 5px;
            }
            &--textarea{
                width: 100%;
                background-color: #323232;
                padding: 5px;
            }
            &--postbtn{
                text-align: center;
            }
            .postbtn{
                width: 150px;
                padding: 10px 0;
                text-align: center;
                border-radius: 10px;
                background: linear-gradient(180deg, #1903031A 3.32%, #323232 94.3%), #00A8A8;
                .postText{
                    font-size: 30px;
                    font-weight: bold;
                    margin-bottom: 7px;
                }
                .postContents{
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 25px;
                    font-size: 18px;
                    img{
                        width: 15px;
                        margin-right: 10px;
                    }
                }
                .loading-overlay {
                    color: #00A8A8;
                    font-size: 10px;
                }
            }
            .postbtn.disabled {
                height: 111px;
                color: #aaa9a9;
                background: linear-gradient(180deg, #1903031A 3.32%, #323232 94.3%), #9b9898;
                cursor: not-allowed;
            }
            // スクロールバーを非表示にする
            &::-webkit-scrollbar {
                display: none;
            }
            // スクロール可能な状態でスクロールバーを表示する
            &:hover,
            &:active,
            &:focus {
                &::-webkit-scrollbar {
                display: block;
                width: 0px; // スクロールバーの幅
                }
                &::-webkit-scrollbar-track {
                background-color: transparent;
                }
                &::-webkit-scrollbar-thumb {
                background-color: #828f85; // スクロールバーの色
                border-radius: 5px;
                }
            }
        }
    }

    .modalbox--img{
        width: 96%;
        .images{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
        }
        input.image{
            cursor: pointer;
            width: 25rem;
            height: 30rem;
            background: url("../../assets/imgae.png") no-repeat center center; /* 画像のパスと表示設定 */
            background-size: contain;
            font-size: 0;
        }
        .image::file-selector-button {
            display: none;
        }
        .image::file-selector-button::before {
            content: none;
        }
    }
    .hidden {
        cursor: not-allowed;
        pointer-events: none;
        display: none;
    }

    .tag{
        a{
            font-size: 13px;
            letter-spacing: 0px;
            margin-left: 10px;
        }
    }

    // 記事の削除/保存確認DLG、確認メッセージDLG　オーバーレイ
    .MessageModal {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #00000080;
        z-index: 11000;
    }
    // 記事の削除/保存確認DLG
    .back_MessageModal_Body {
        background-color: #323232;
        color: #fff;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #00a8a8;
        text-align: center;
        z-index: 40;

        p{
            font-size: 18px;
            font-weight: 500;
        }
    }
    .NotLxMsgModal_Body {
        background-color: #323232;
        color: #fff;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #00a8a8;
        text-align: center;
        z-index: 40;

        .closeNotLxMsgbtn {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 15px;
            img{
                cursor: pointer;
                width: 20px;
            }
        }
        p{
            font-size: 18px;
            font-weight: 500;
        }
        .btn_Modal {
            img{
                width: 15px;
                margin-right: 5px;
            }
        }
    }
    // 確認メッセージDLG
    .MessageModal_Body {
        background-color: #323232;
        color: #fff;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #00a8a8;
        text-align: center;
        width: 300px;
        height: 110px;
        z-index: 40;

        p{
            font-size: 18px;
            font-weight: 500;
        }
    }
    // 記事の削除/保存確認DLG、確認メッセージDLG ボタン
    .btn_Modal {
        margin: 20px 8px 0 8px;
        border: none;
        border-radius: 5px;
        width: 100px;
        height: 35px;
        font-size: 16px;
        background: linear-gradient(180deg, #1903031A 3.32%, #4d4d4d 94.3%), #00A8A8;
    }
</style>