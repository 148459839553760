<!-- footerNav.vue -->

<template>
    <!-- コメント投稿 -->
    <div v-if="isPostDetailPage" class="FooterCommentBar">
        <input type="text" placeholder="コメント追加" v-model="commentText">
        <button @click="addNewcomment">
            <img src="../../assets/comment.png" alt="コメント" class="smallicon">
        </button>
    </div>

    <nav class="footerNav">
        <!-- マイページボタン -->
        <button class="footerNav__mypage footer-w" @click="handleButtonClick('mypage')">
            <div><img src="../../assets/myicon.png" alt="" class="smallicon">{{ mypageText }}</div>
        </button>

        <!-- 課金遷移ボタン -->
        <div class="footerNav__billing footer-w" @click="handleButtonClick('billing')">
            <div class="footerNav__billing">
                <p> {{ userPoints || 0 }}</p>
                <button>
                    <img src="../../assets/LXmark.png" alt="" class="smallicon">
                </button>
            </div>
        </div>

        <!-- 検索ボタン -->
        <div class="footerNav__search footer-w" @click="searchmodal">
            <p><img src="../../assets/search.png" alt="" class="smallicon search-img">検 索</p>
        </div>
        <!-- 投稿ボタン -->
        <!-- footerNav.vue -->
        <div class="footerNav__postbtn" @click="handleButtonClick('post')">
            <img src="../../assets/post.png" alt="">
        </div>
    </nav>

    <!-- 検索入力 -->
    <SerchModal :isOpen="isSearchModalOpen" @update:isOpen="updateSearchModalState" />

    <!-- ChargeModal コンポーネントの追加 -->
    <ChargeModal :modalOpen="modalOpen" @closeChargeModal="closeChargeModal"></ChargeModal>

    <PostModal v-if="isPostModalOpen" :isPostModalOpen="isPostModalOpen" :selectDay="selectDay" @update:isPostModalOpen="updatePostModalState" />
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import PostModal from "../FooterNavComp/postModal.vue";
import { useRoute, useRouter } from 'vue-router';
import { auth } from '../../main';
import { doc, getDoc, collection, addDoc, setDoc, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../main';
import ChargeModal from '../chargeModal/chargeModal.vue';
import SerchModal from "../FooterNavComp/SerchModal.vue";

export default {
    components: {
        PostModal,
        ChargeModal,
        SerchModal
    },
    
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const currentUser = ref(null);
        const userPoints = ref(null);
        const isPostModalOpen = ref(false);
        const isSearchModalOpen = ref(false);
        const ischargeModalOpen = ref(false);
        const searchText = ref('');
        const selectDay = ref(null);
        const isPostDetailPage = ref(route.name === 'postdetail');
        const commentText = ref(''); // コメントのテキストを保持するリアクティブ変数を追加
        
        const modalOpen = ref(false);

        const mypageText = computed(() => route.name === 'user' ? 'DiPosty' : 'マイページ');
        const mypageLink = computed(() => route.name === 'user' ? '/' : `/user/${currentUser.value ? currentUser.value.uid : ''}`);

        const openChargeModal = () => {
            //console.log("チャージ")
            modalOpen.value = true;
        };

        const closeChargeModal = () => {
            modalOpen.value = false;
        };

        const isLoggedIn = ref(false); // ログイン状態を追跡


        let unsubscribe = null; // リスナーの解除用

        onMounted(async () => {
            auth.onAuthStateChanged(async (user) => {
                currentUser.value = user;
                if (user) {
                    isLoggedIn.value = true;
                    // Firestore のリアルタイムリスナーを設定
                    const userDocRef = doc(db, 'users', user.uid);
                    unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
                        if (docSnapshot.exists()) {
                            userPoints.value = docSnapshot.data().point || 0;
                        } else {
                            userPoints.value = 0;
                        }
                    }, (error) => {
                        window.alert('データ取得中にエラーが発生しました。');
                    });
                } else {
                    isLoggedIn.value = false;
                    userPoints.value = 0;
                }
            });
        });

        onUnmounted(() => {
            // リスナーを解除してメモリリークを防ぐ
            if (unsubscribe) {
                unsubscribe();
            }
        });

        const generateRandomId = () => {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let randomId = '';
            for (let i = 0; i < 20; i++) {
                randomId += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return randomId;
        };

        const openPostModal = () => {
            // セッションストレージの selectDAY を取得
            const selectDAY = sessionStorage.getItem('selectDAY');
            //console.log('selectDAYチェックーモーダル開く:', selectDAY);
            if (selectDAY) {
                selectDay.value = selectDAY;
            } else {
                const today = new Date();
                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, '0');
                const day = String(today.getDate()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}`;
                sessionStorage.setItem('selectDAY', formattedDate);
                selectDay.value = formattedDate;
            }

            isPostModalOpen.value = true;
        };

        const updatePostModalState = (newState) => {
            isPostModalOpen.value = newState;
        };
        const updatechargeModalState = (newState) => {
            ischargeModalOpen.value = newState;
        };

        const searchmodal = () => {
            // ログインしているかどうかを確認
            if (!isLoggedIn.value) {
                if (confirm("ログインしてください")) {
                    router.push('/login');
                }
                return; // 未ログインの場合は処理を中断
            }

            isSearchModalOpen.value = true;
        };

        const updateSearchModalState = (isOpen) => {
            // 検索モーダルの状態を更新
            isSearchModalOpen.value = isOpen;
        };

        const addNewcomment = async () => {
            //console.log('currentUser.value:', currentUser.value);
            //console.log('commentText:', commentText.value.trim());

            // コメントテキストが空の場合、アラートを表示して処理を中断
            if (commentText.value.trim() === '') {
                window.alert('テキストを入力してください');
                return;
            }

            try {
                const articleId = route.params.id;
                const articleRef = doc(db, 'whispers', articleId);

                // 新しいドキュメントを作成し、ランダムなドキュメントIDを指定してコメントを追加する
                const commentsCollectionRef = collection(articleRef, 'comments');
                const newCommentDocRef = doc(commentsCollectionRef);

                // ドキュメントIDを取得
                const randomDocId = newCommentDocRef.id;
                // mycomments コレクションにも同じドキュメント名で追加する
                const userDocRef = doc(db, 'users', currentUser.value.uid);
                const userDocSnap = await getDoc(userDocRef);
                const originidValue = userDocSnap.data().originid;

                const myCommentsCollectionRef = collection(userDocRef, 'mycomments');
                const newMyCommentDocRef = doc(myCommentsCollectionRef, randomDocId);

                // コメントを追加する
                await setDoc(newCommentDocRef, {
                    originid: originidValue,
                    text: commentText.value,
                    point: 0,
                    postid: articleId,
                });

                await setDoc(newMyCommentDocRef, {
                    postid: articleId,
                });

                //console.log('コメントが追加されました。');

                // 合算した文字数を保存する
                const totalLength = commentText.value.length;
                const todayDate = new Date();
                const formattedDate = todayDate.toISOString().split('T')[0].replace(/-/g, '');

                const lengthLogRef = doc(db, 'users', currentUser.value.uid, 'myCommentlengthLog', formattedDate);
                const lengthLogDoc = await getDoc(lengthLogRef);

                if (lengthLogDoc.exists()) {
                    // 既存のデータに加算する
                    const existingData = lengthLogDoc.data();
                    const updatedData = {
                        ALLlength: (existingData.ALLlength || 0) + totalLength
                    };
                    await setDoc(lengthLogRef, updatedData, { merge: true });
                } else {
                    // 新しいデータを作成する
                    const charCountData = {
                        ALLlength: totalLength
                    };
                    await setDoc(lengthLogRef, charCountData, { merge: true });
                }

                isPostModalOpen.value = false;
                //console.log('投稿が完了しました！');
                alert('投稿が完了しました！');

                commentText.value = ''; // コメントを追加した後、入力フィールドをクリア
                window.location.reload();
            } catch (error) {
                //console.log('コメントの追加中にエラーが発生しました:', error);
            }
        };


        const watchPayments = () => {
            const paymentsCollectionRef = collection(db, 'customers', auth.currentUser.uid, 'payments');
            const querySnapshot = query(paymentsCollectionRef);
            onSnapshot(querySnapshot, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    if (change.type === 'added') {
                        const payment = change.doc.data();
                        if (payment.status === 'succeeded') {
                            const amount = payment.amount;
                            updateUserPoint(amount);
                        }
                    }
                });
            });
        };

        // ユーザーのポイントを更新する
        const updateUserPoint = async (amount) => {
            try {
                const userDocRef = doc(db, 'users', auth.currentUser.uid); // .value.uid を追加
                const userDocSnapshot = await getDoc(userDocRef);
                if (userDocSnapshot.exists()) {
                    const currentPoint = userDocSnapshot.data().point;
                    const updatedPoint = currentPoint + amount;
                    await userDocRef.update({ point: updatedPoint });
                    //console.log('ユーザーのポイントが更新されました。');
                }
            } catch (error) {
                //console.log('ユーザーのポイントの更新中にエラーが発生しました:', error);
            }
        };

        const handleButtonClick = (buttonType) => {
            // ログインしているかどうかを確認
            if (!isLoggedIn.value) {
                if (confirm("ログインしてください")) {
                    router.push('/login');
                }
                return; // 未ログインの場合は処理を中断
            }

            // ログインしている場合の処理をボタンの種類に応じて分岐
            switch(buttonType) {
                case 'mypage':
                router.push(mypageLink.value); // マイページへの遷移
                    break;
                case 'billing':
                    // 課金遷移の処理をここに追加
                    openChargeModal();
                    break;
                case 'post':
                    // 投稿ボタンの処理
                    isPostModalOpen.value = true;
                    break;
                default:
                    console.log('未知のボタンタイプ:', buttonType);
            }
        };



        return {
            modalOpen,
            closeChargeModal,
            openChargeModal,
            currentUser,
            userPoints,
            isPostModalOpen,
            isSearchModalOpen,
            ischargeModalOpen,
            searchText,
            commentText, // テキスト入力フィールドの内容を保持するリアクティブ変数を返す
            updateSearchModalState,
            openPostModal,
            updatePostModalState,
            updatechargeModalState,
            searchmodal,
            addNewcomment,
            route,
            isPostDetailPage,
            generateRandomId,
            selectDay,
            mypageText,
            mypageLink,
            isLoggedIn,
            handleButtonClick
        };
    }
};
</script>






<style lang="scss" scoped>

    .FooterCommentBar{
        width: 590px;
        height: 60px;
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translate(-50%,-0%);
        background: linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: #000;
        @media (max-width: 599px) {
            width: 100%;
            min-width: 320px;
            height: 60px;
        }
        input{
            border-radius: 20px;
            border: 0.5px solid #000;
            background: #D9D9D9;
            width: 90%;
            height: 40px;
            padding-left: 5px;
            margin-left: 10px;
        }
        img{
            height: 30px;
            margin: 0 10px;
        }
    }

    .footerNav{
        width: 590px;
        height: 60px;
        z-index: 2;
        min-width: 450px;
        @media (max-width: 599px) {
            width: 100%;
            min-width: 320px;
        }
        position: fixed;
        bottom: 0%;
        left: 50%;
        transform: translate(-50%,-0%);
        background: linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: #fff;
        &__postbtn{
            height: 60px;
            cursor: pointer;
            background: linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;;
            img{
                padding: 10px 15px;
                width: 100%;
                height: 100%;
                object-fit: contain;
                @media (max-width: 599px) {
                    padding: 5px 10px;
                }
            }
        }
        &__mypage{
            width: 50%;
            @media (max-width: 599px) {
                font-size: 12px;
                padding: 0 5px;
            }
            .smallicon{
                margin-right: 5px;
            }
        }
        &__billing{
            width: 90%;
            margin: 5px;
            background-color: #D9D9D9;
            border-radius: 30px;
            height: 38px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 599px) {
                padding: 0 5px;
            }
            @media (max-width: 380px) {
                margin: 0;
                padding: 0;
            }
            p{
                display: inline!important;
                padding-left: 15px;
                color: #323232;
                @media (max-width: 599px) {
                    padding-left: 5px;
                    font-size: 12px;
                }
            }
            img{
                height: 36px;
                width: 36px!important;
                margin-right: 2px!important;
                @media (max-width: 599px) {
                    margin-right: -3px !important;
                }
                @media (max-width: 380px) {
                    margin-right: 1px !important;
                }
            }
        }
        &__search{
            @media (max-width: 599px) {
                width: 25%!important;
            }
            @media (max-width: 390px) {
                padding: 0 8px 0 0;
            }
            .smallicon{
                margin-right: 5px;
                @media (max-width: 390px) {
                    margin-right: 0;
                }
            }
        }
        .footer-w{
            cursor: pointer;
            width: 30%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
            @media (max-width: 599px) {
                width: 38%;
            }
            p{
                display: contents;
                @media (max-width: 599px) {
                    font-size: 12px;
                }
            }
            .smallicon{
                width: 16px;
            }
            .search-img{
                width: 30px;
            }
            span{
                padding-left: 6px;
            }
        }
        .backbtn{
            position: relative;
            top: 5px;
            left: 90%;
            width: 16px;
            height: 5px;
            img{
                cursor: pointer;
                width: 16px;
            }
        }
    }
    .footerNav__search .footer-w {
        @media (max-width: 390px) {
            padding: 0;
        }
    }

    .disabled {
        cursor: not-allowed;
        pointer-events: none
    }
</style>